import { toast } from "sonner";
import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";

export default function useFetch(
  callback,
  dependencies = [],
  checkStatement = true
) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(null);
  const { isReady: isRouterReady } = useRouter(); // Check if the query params are ready

  const callbackMemoized = useCallback(() => {
    setIsLoading(true);
    setError(null);
    setValue(null);
    callback()
      .then(setValue)
      .catch(error => {
        const errorMessage =
          error?.response?.data?.message ??
          (error.response?.status == 502
            ? "يتم رفع تحديث لتحسين المنصة"
            : "هناك خطأ ما");
        toast.error(errorMessage);
        setError(errorMessage);
      })
      .finally(() => setIsLoading(false));
  }, dependencies);

  useEffect(() => {
    if (!checkStatement || !isRouterReady) return;

    callbackMemoized();
  }, [callbackMemoized, checkStatement, isRouterReady]);

  return { isLoading, error, value, setValue, refetch: callbackMemoized };
}
